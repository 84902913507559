import {i18n} from 'i18n'

import {DEFAULT_IMAGE} from 'common/helpers/general'
import {
  LEGACY_SUPPORTED_LANGUAGES,
  MAX_DESCRIPTION_LENGTH,
  MAX_DESCRIPTION_LENGTH_WITH_DOTS
} from 'consts.json'

/**
 * Return meta description
 *
 * @param {String} content text.
 */
export const getMetaDescription = content => {
  return content.length > MAX_DESCRIPTION_LENGTH
    ? `${content.substr(0, MAX_DESCRIPTION_LENGTH_WITH_DOTS)}...`
    : content
}

/**
 * Resolve meta config
 */
export const resolveMetaConfig = (
  title = 'Cultural Places',
  description = '',
  images = [{url: DEFAULT_IMAGE}],
  canonical
) => {
  return {
    title: title,
    description: description,
    canonical,
    openGraph: {
      title: title,
      description: description,
      images,
      site_name: 'Cultural Places'
    },
    twitter: {
      handle: '@handle',
      site: '@site',
      cardType: 'summary_large_image'
    },
    additionalMetaTags: [
      {
        name: 'facebook-domain-verification',
        content: 'gm2lp5frh7oa9wier4u2rbdgqnnhui'
      }
    ]
  }
}

export function getSeoConfig({id, ctx, asPath, title, subtitle, description, images, canonical}) {
  let path = ''

  if (asPath) {
    path = asPath
  } else if ('asPath' in ctx) {
    path = ctx.asPath.split('?')[0]
  } else if ('req' in ctx && 'url' in ctx.req) {
    path = ctx.req.url.split('?')[0]
  }

  const urlPieces = path.split('/')
  if (urlPieces.length >= 1 && LEGACY_SUPPORTED_LANGUAGES.includes(urlPieces[1])) {
    urlPieces.splice(1, 1)
  }

  const trans = (e, arg) => {
    if ('req' in ctx && 't' in ctx.req) {
      // Translate on server
      return ctx.req.t(e, arg)
    } else {
      // Translate on client
      return i18n.t(e, arg)
    }
  }

  let seoTitle = trans(title)

  if (id) {
    switch (id) {
      case 'destination':
        seoTitle = trans('DESTINATION_PAGE_BROWSER_TITLE', {TITLE: title})
        break
      case 'institution':
        seoTitle = trans('INSTITUTION_PAGE_BROWSER_TITLE', {TITLE: title})
        break
      case 'helpingwithculturalplaces':
        seoTitle =
          trans('HELPING_WITH_CULTURAL_PLACES_TITLE') +
          ' ' +
          trans('HELPING_WITH_CULTURAL_PLACES_TITLE_BOLD')
        break
    }
  }
  const seoSubtitle = subtitle ? trans(subtitle) : trans('SEO_CULTURAL_PLACES')

  return {
    title: `${seoTitle}  | ${seoSubtitle}`,
    description: trans(description),
    images: images || null,
    canonical: canonical || null
  }
}
